import React from 'react';

import CheckoutActions from 'root/client/components/checkout-actions/checkout-actions';
import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CheckoutSteps from 'root/client/components/checkout-steps/checkout-steps';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ConfirmationSummary from 'root/client/components/confirmation-summary/confirmation-summary';
import ContentContainer from 'root/client/components/content-container/content-container';
import Message from 'root/client/components/message/message';
import Payment from 'root/client/components/payment/payment';

import type { CheckoutPaymentPage as Props } from './checkout-payment-page.types';

const CheckoutPaymentPage: React.FunctionComponent<Props> = ({
  checkoutActions,
  checkoutSteps,
  errors = [],
  layout,
  payment,
  paymentDisclaimer,
  paymentMissing,
  summary,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer>
      <CheckoutHeader title={title} />
      <CheckoutSteps {...checkoutSteps} />
    </ContentContainer>
    {errors.length ? (
      <ContentContainer>
        <div className="checkout-payment-page__errors">
          {errors.map(text => (
            <Message key={text} text={text} type={Message.types.error} />
          ))}
        </div>
      </ContentContainer>
    ) : null}
    <CheckoutActions {...checkoutActions}>
      <div className="checkout-payment-page__content">
        <div className="checkout-payment-page__payment">
          {payment ? (
            <React.Fragment>
              <Payment {...payment} />
              {paymentDisclaimer ? (
                <div className="checkout-payment-page__disclaimer">
                  <Message text={paymentDisclaimer} type={Message.types.info} />
                </div>
              ) : null}
            </React.Fragment>
          ) : (
            <Message text={paymentMissing} type={Message.types.info} />
          )}
        </div>
        <div className="checkout-payment-page__summary">
          <ConfirmationSummary {...summary} />
        </div>
      </div>
    </CheckoutActions>
  </CleanLayout>
);

export default CheckoutPaymentPage;
