import type { ReactNode } from 'react';

export enum Theme {
  Centered = 'centered',
  FullScreen = 'full-screen',
}

export type Modal = {
  closeButtonText: string;
  hide?: () => void;
  isVisible?: boolean;
  theme?: Theme;
  title?: ReactNode;
};
