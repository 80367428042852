import type { FC } from 'react';
import AboutFreshPage from './pages/about-fresh-page/about-fresh-page';
import AlternateCheckoutAboutYou from './pages/alternate-checkout-about-you/alternate-checkout-about-you';
import AlternateCheckoutConfirmation from './pages/alternate-checkout-confirmation/alternate-checkout-confirmation';
import AlternateCheckoutMembership from './pages/alternate-checkout-membership/alternate-checkout-membership';
import AlternateCheckoutPayment from './pages/alternate-checkout-payment/alternate-checkout-payment';
import AlternateCheckoutSelectClub from './pages/alternate-checkout-select-club/alternate-checkout-select-club';
import AlternateCheckoutStatus from './pages/alternate-checkout-status/alternate-checkout-status';
import ArticlePage from './pages/article-page/article-page';
import ArticlesPage from './pages/articles-page/articles-page';
import CheckoutConfirmationPage from './pages/checkout-confirmation-page/checkout-confirmation-page';
import CheckoutPaymentErrorPage from './pages/checkout-payment-error-page/checkout-payment-error-page';
import CheckoutPaymentPage from './pages/checkout-payment-page/checkout-payment-page';
import CheckoutRegisterMemberPage from './pages/checkout-register-member-page/checkout-register-member-page';
import CheckoutSelectClubPage from './pages/checkout-select-club-page/checkout-select-club-page';
import CheckoutSelectMembershipPage from './pages/checkout-select-membership-page/checkout-select-membership-page';
import CheckoutSelectProductPage from './pages/checkout-select-product-page/checkout-select-product-page';
import ClubListPage from './pages/club-list-page/club-list-page';
import ClubPage from './pages/club-page/club-page';
import ClubsMapPage from './pages/clubs-map-page/clubs-map-page';
import CustomerService from './pages/customer-service/customer-service';
import CustomerServiceCategory from './pages/customer-service-category/customer-service-category';
import DropInConfirmationPage from './pages/drop-in-confirmation-page/drop-in-confirmation-page';
import DropInPaymentPage from './pages/drop-in-payment-page/drop-in-payment-page';
import DropInRegisterMemberPage from './pages/drop-in-register-member-page/drop-in-register-member-page';
import DropInSelectClubPage from './pages/drop-in-select-club-page/drop-in-select-club-page';
import EmployeeLogInPage from './pages/employee-log-in-page/employee-log-in-page';
import ErrorPage from './pages/error-page/error-page';
import GroupExerciseTypePage from './pages/group-exercise-type-page/group-exercise-type-page';
import GroupExerciseTypesPage from './pages/group-exercise-types-page/group-exercise-types-page';
import LandingPage from './pages/landing-page/landing-page';
import MemberCareFormPage from './pages/member-care-form-page/member-care-form-page';
import PersonalTrainerPage from './pages/personal-trainer-page/personal-trainer-page';
import PersonalTrainersPage from './pages/personal-trainers-page/personal-trainers-page';
import PersonalTrainingPage from './pages/personal-training-page/personal-training-page';
import RootPage from './pages/root-page/root-page';
import WaitingArea from './pages/waiting-area/waiting-area';
import WebAdminPage from './pages/web-admin-page/web-admin-page';
import WorkoutOfferPage from './pages/workout-offer-page/workout-offer-page';

/** This object is automatically generated. */
export const pageComponents: Record<string, FC> = {
  AboutFreshPage,
  AlternateCheckoutAboutYou,
  AlternateCheckoutConfirmation,
  AlternateCheckoutMembership,
  AlternateCheckoutPayment,
  AlternateCheckoutSelectClub,
  AlternateCheckoutStatus,
  ArticlePage,
  ArticlesPage,
  CheckoutConfirmationPage,
  CheckoutPaymentErrorPage,
  CheckoutPaymentPage,
  CheckoutRegisterMemberPage,
  CheckoutSelectClubPage,
  CheckoutSelectMembershipPage,
  CheckoutSelectProductPage,
  ClubListPage,
  ClubPage,
  ClubsMapPage,
  CustomerService,
  CustomerServiceCategory,
  DropInConfirmationPage,
  DropInPaymentPage,
  DropInRegisterMemberPage,
  DropInSelectClubPage,
  EmployeeLogInPage,
  ErrorPage,
  GroupExerciseTypePage,
  GroupExerciseTypesPage,
  LandingPage,
  MemberCareFormPage,
  PersonalTrainerPage,
  PersonalTrainersPage,
  PersonalTrainingPage,
  RootPage,
  WaitingArea,
  WebAdminPage,
  WorkoutOfferPage,
};
