import cn from 'classnames';
import React from 'react';
import SvgArrowDown from '@sats-group/icons/18/arrow-down';
import SvgBooked from '@sats-group/icons/16/booked';
import SvgRemove from '@sats-group/icons/16/remove';

import useToggle from 'root/client/hooks/use-toggle';

import Collapse from '../collapse/collapse';
import Price from '../price/price';
import Text from '../text/text';

import type { Choicebox as Props } from './choicebox.types';

const Choicebox: React.FC<Props> = ({
  description,
  heading,
  href,
  id,
  price,
  extra,
  iconRaw,
  onClick,
  selected,
  tag,
}) => {
  const [isExtraOpen, toggleExtra] = useToggle(false);

  const extraId = `${id}-extra-content`;

  return (
    <div className={cn('choicebox', { 'choicebox--selected': selected })}>
      {tag ? (
        <div
          className={cn('choicebox__tag', {
            'choicebox__tag--selected': selected,
          })}
        >
          <Text size={Text.sizes.header3} variant={Text.variants.contrast}>
            {tag}
          </Text>
        </div>
      ) : null}
      {React.createElement(
        href ? 'a' : 'div',
        {
          className: cn('choicebox__main', {
            'choicebox__main--selected': selected,
          }),
          href,
          onClick,
        },
        <React.Fragment>
          <div
            className={cn('choicebox__header', {
              'choicebox__header--selected': selected,
            })}
          >
            {iconRaw ? (
              <div aria-hidden>
                <div dangerouslySetInnerHTML={{ __html: iconRaw }} />
              </div>
            ) : null}
            <Text
              size={extra ? Text.sizes.header2 : Text.sizes.large}
              tight
              variant={extra ? Text.variants.contrast : Text.variants.content}
            >
              {heading}
            </Text>
            <div aria-hidden>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                <circle
                  cx="14"
                  cy="14"
                  r="9.25"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                {selected ? (
                  <circle cx="14" cy="14" r="6" fill="currentColor" />
                ) : null}
              </svg>
            </div>
          </div>
          {description ? (
            <div className="choicebox__description">
              <Text>{description}</Text>
            </div>
          ) : null}
          {extra ? (
            <Collapse isOpen={isExtraOpen}>
              <ul className="choicebox__extra" id={extraId}>
                {extra.content.map(entry => (
                  <li
                    className={cn(
                      'choicebox__usp',
                      entry.active
                        ? 'choicebox__usp--active'
                        : 'choicebox__usp--inactive',
                    )}
                    key={entry.text}
                  >
                    <div>{entry.active ? <SvgBooked /> : <SvgRemove />}</div>
                    <Text>{entry.text}</Text>
                  </li>
                ))}
              </ul>
            </Collapse>
          ) : null}
          {price ? (
            <div className="choicebox__price">
              <Price {...price} />
            </div>
          ) : null}
        </React.Fragment>,
      )}
      {extra ? (
        <div>
          <button
            aria-controls={extraId}
            className={cn('choicebox__trigger', {
              'choicebox__trigger--selected': selected,
            })}
            onClick={toggleExtra}
            type="button"
          >
            <Text size={Text.sizes.small}>{extra.trigger}</Text>
            <div
              aria-hidden
              className={cn('choicebox__trigger-flourish', {
                'choicebox__trigger-flourish--flipped': isExtraOpen,
              })}
            >
              <SvgArrowDown />
            </div>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Choicebox;
