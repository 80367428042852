import ArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React from 'react';

import Button from '../button/button';
import Collapse from '../collapse/collapse';
import Discount from '../discount/discount';
import HiddenInput from '../hidden-input/hidden-input';
import Text from '../text/text';
import TextField from '../text-field/text-field';
import useToggle from '../../hooks/use-toggle';

import type { DiscountSection as Props } from './discount-section.types';

const DiscountSection: React.FunctionComponent<Props> = ({
  active,
  description,
  id,
  input,
  onSubmit,
  options,
  title,
  trigger,
}) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div className="discount-section">
      <div className="discount-section__text discount-section__text--contrast">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
      {active ? (
        <div className="discount-section__active">
          <div className="discount-section__text discount-section__text--muted">
            <Text>{active.title}</Text>
          </div>
          <Discount {...active.discount} active />
        </div>
      ) : null}
      <div className="discount-section__change">
        <button
          aria-controls={id}
          aria-expanded={isOpen}
          className="discount-section__trigger"
          onClick={toggleOpen}
        >
          <Text>{trigger}</Text>
          <div
            className={cn('discount-section__trigger-flourish', {
              'discount-section__trigger-flourish--active': isOpen,
            })}
          >
            <ArrowDown />
          </div>
        </button>
        <Collapse isOpen={isOpen}>
          <div className="discount-section__content" id={id}>
            <div className="discount-section__text discount-section__text--muted">
              <Text size={Text.sizes.small}>{description}</Text>
            </div>
            <form
              className="discount-section__input"
              method="get"
              onSubmit={onSubmit}
            >
              {input.data.map(entry => (
                <HiddenInput {...entry} key={`${entry.name}-${entry.value}`} />
              ))}
              <div>
                <TextField {...input.code} wide />
              </div>
              <div>
                <Button
                  {...input.trigger}
                  size={Button.sizes.small}
                  theme={Button.themes.secondary}
                  type="submit"
                  wide
                />
              </div>
            </form>
            {options ? (
              <div className="discount-section__options">
                <div className="discount-section__text discount-section__text--muted">
                  <Text size={Text.sizes.small} theme={Text.themes.emphasis}>
                    {options.title}
                  </Text>
                </div>
                {options.entries.map(entry => (
                  <div key={entry.title}>
                    <Discount {...entry} />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DiscountSection;
