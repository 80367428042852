import React from 'react';

import Text from 'root/client/components/text/text';

import type { ConfirmationSummary as Props } from './confirmation-summary.types';

const ConfirmationSummary: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ cells = [], lines = [] }) =>
  cells.length === 0 && lines.length === 0 ? null : (
    <div className="confirmation-summary">
      {cells.length === 0 ? null : (
        <dl className="confirmation-summary__cells">
          {cells.map(({ label, value }) => (
            <div
              className="confirmation-summary__cell"
              key={`${label}-${value}`}
            >
              <dt>
                <Text size={Text.sizes.small}>{label}</Text>
              </dt>
              <dd>
                <Text size={Text.sizes.basic}>{value}</Text>
              </dd>
            </div>
          ))}
        </dl>
      )}
      {lines.length === 0 ? null : (
        <dl className="confirmation-summary__lines">
          {lines.map(({ isImportant, label, value }) => (
            <div
              className={'confirmation-summary__line'}
              key={`${label}-${value}`}
            >
              <dt>
                <Text size={isImportant ? Text.sizes.basic : Text.sizes.small}>
                  {label}
                </Text>
              </dt>
              <dd>
                <Text size={isImportant ? Text.sizes.basic : Text.sizes.small}>
                  {value}
                </Text>
              </dd>
            </div>
          ))}
        </dl>
      )}
    </div>
  );

export default ConfirmationSummary;
