import cn from 'classnames';
import * as React from 'react';

import LinkButton from '../link-button/link-button';
import Text from '../text/text';

import { type CTABanner as Props, themes, variants } from './cta-banner.types';

const CTABanner: React.FunctionComponent<Props> & {
  themes: typeof themes;
  variants: typeof variants;
} = ({
  link,
  text,
  theme = themes.white,
  title,
  variant = variants.secondary,
}) => {
  let linkButtonTheme;

  switch (variant) {
    case 'main': {
      if (theme === 'fixed') {
        linkButtonTheme = LinkButton.themes.primaryWhite;
      }
      if (theme === 'white') {
        linkButtonTheme = LinkButton.themes.cta;
      }
      break;
    }
    case 'secondary': {
      if (theme === 'fixed') {
        linkButtonTheme = LinkButton.themes.ctaSecondaryWhite;
      }
      if (theme === 'white') {
        linkButtonTheme = LinkButton.themes.ctaSecondary;
      }
      break;
    }
  }
  return (
    <div
      className={cn('cta-banner', {
        [`cta-banner--theme-${theme}`]: theme,
      })}
    >
      <Text
        size={Text.sizes.header3}
        theme={Text.themes.emphasis}
        variant={Text.variants.hero}
      >
        {title}
      </Text>
      <Text>{text}</Text>
      <div className="cta-banner__button">
        <LinkButton {...link} theme={linkButtonTheme} />
      </div>
    </div>
  );
};
CTABanner.themes = themes;
CTABanner.variants = variants;

export default CTABanner;
