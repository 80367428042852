import React, { useState } from 'react';
import Search16 from '@sats-group/icons/16/search';

import useToggle from 'root/client/hooks/use-toggle';

import Collapse from '../collapse/collapse';
import HiddenInput from '../hidden-input/hidden-input';
import Tag from '../tag/tag';
import Text from '../text/text';

import type { FilterOption, PhoneInput as Props } from './phone-input.types';

const PhoneInput: React.FunctionComponent<Props> = ({
  name,
  label,
  prefix,
  required,
  search,
}) => {
  const [countryCode, setCountryCode] = useState<FilterOption>(
    prefix.defaultValue,
  );
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [filter, toggleFilter] = useToggle(false);

  const [countryCodeSearch, setCountryCodeSearch] = useState<FilterOption[]>(
    prefix.filter.allOptions,
  );

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handlePrefix = (prefix: FilterOption) => {
    setCountryCode(prefix);
    toggleFilter();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const options = prefix.filter.allOptions.filter(option =>
      (option.country + option.dialCode)
        .toLowerCase()
        .includes(event.target.value.toLocaleLowerCase()),
    );
    setCountryCodeSearch(options);
  };

  return (
    <div className="phone-input">
      <HiddenInput
        name={name}
        value={`${countryCode.dialCode}${phoneNumber}`}
      />
      <label className="phone-input__label">
        <Text size={Text.sizes.basic}>
          {label}
          {required ? ' *' : null}
        </Text>
      </label>
      <div className="phone-input__input-wrapper">
        <button
          className="phone-input__input phone-input__country-code"
          onClick={() => toggleFilter()}
          type="button"
        >
          {countryCode.selectedDisplayValue}
        </button>
        <Collapse className="phone-input__prefix-filter" isOpen={filter}>
          <div>
            <div className="phone-input__prefix-search">
              <Search16 />
              <input onChange={handleSearch} placeholder={search} />
            </div>
            <ul className="phone-input__prefix-filter-options">
              {countryCodeSearch.map(option => (
                <button
                  type="button"
                  className="phone-input__prefix-filter-option"
                  onClick={() => handlePrefix(option)}
                >
                  <div className="phone-input__option-emoji">
                    {option.emoji}
                  </div>
                  <div className="phone-input__option-country">
                    {option.country}
                  </div>
                  <Tag text={option.dialCode} />
                </button>
              ))}
            </ul>
          </div>
        </Collapse>
        <input
          className="phone-input__input phone-input__phone-number"
          type="tel"
          onChange={event => handleInput(event)}
          required
        ></input>
      </div>
    </div>
  );
};

export default PhoneInput;
