import React from 'react';

import RichText from '../../components/rich-text/rich-text';
import Text from '../../components/text/text';

import type { MembershipTerms as Props } from './membership-terms.types';

const MembershipTerms: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ body, introduction, title }) => (
  <div className="membership-terms">
    <div className="membership-terms__text">
      <div className="membership-terms__title">
        <Text
          elementName="h1"
          size={Text.sizes.header2}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>

      <RichText className="membership-terms__introduction" {...introduction} />

      <RichText className="membership-terms__body" {...body} />
    </div>
  </div>
);

export default MembershipTerms;
