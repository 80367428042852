import type { ValueOf } from 'type-fest';

import type { LinkButton } from '../link-button/link-button.types';

export const themes = {
  fixed: 'fixed',
  white: 'white',
} as const;

export const variants = {
  main: 'main',
  secondary: 'secondary',
} as const;

export type CTABanner = {
  link: LinkButton;
  text: string;
  theme?: ValueOf<typeof themes>;
  variant?: ValueOf<typeof variants>;
  title: string;
};
