import cn from 'classnames';
import React from 'react';

import Text from '../text/text';

import type { Price as Props } from './price.types';
import { themes } from './price.types';

const Price: React.FC<Props> & { themes: typeof themes } = ({
  disclaimer,
  originalPrice,
  pre,
  post,
  theme,
  value,
}) => (
  <div className="price">
    {originalPrice ? (
      <Text className="price__original-price">{originalPrice}</Text>
    ) : null}
    <div className={cn('price__main', { [`price__main--${theme}`]: theme })}>
      {pre ? <Text theme={Text.themes.emphasis}>{pre}</Text> : null}
      <Text variant={Text.variants.hero} size={Text.sizes.header3}>
        {value}
      </Text>
      <Text theme={Text.themes.emphasis}>{post}</Text>
    </div>
    {disclaimer ? (
      <Text className="price__disclaimer" size={Text.sizes.small}>
        {disclaimer}
      </Text>
    ) : null}
  </div>
);

Price.themes = themes;

export default Price;
