import React, { useRef, useState } from 'react';

import { replaceQueryParameters } from 'root/shared/replace-query-parameters';

import Banner from '../../components/banner/banner';
import Price from '../../components/price/price';
import SalesLayout from '../../components/sales-layout/sales-layout';
import Spinner from '../../components/spinner/spinner';
import Text from '../../components/text/text';
import { type Core, useAdyen } from '../../hooks/use-adyen';
import useAsyncEffect from '../../hooks/use-async-effect';

import type { AlternateCheckoutPayment as Props } from './alternate-checkout-payment.types';

const AlternateCheckoutPayment: React.FC<Props> = ({
  layout,
  messages,
  payment,
  summaries,
}) => {
  const checkout = useRef<Core>();
  const dropinContainer = useRef<HTMLDivElement>(null);
  const [localMessages, setLocalMessages] = useState<
    Parameters<typeof Banner>[0][]
  >([]);
  const handleAdyenLoadingError = () =>
    setLocalMessages([messages.errorLoading]);
  const [isLoading, hasLoaded] = useAdyen(handleAdyenLoadingError);

  useAsyncEffect(async () => {
    if (
      !dropinContainer.current ||
      !hasLoaded ||
      !payment.session ||
      !window.AdyenCheckout ||
      isLoading
    ) {
      return;
    }

    setLocalMessages([]);

    const adyenConfiguration: Parameters<typeof window.AdyenCheckout>[0] = {
      clientKey: payment.clientKey,
      environment: payment.environment,
      locale: payment.locale,
      onError: () => {
        setLocalMessages([messages.errorCheckout]);
        if (checkout.current) {
          checkout.current.update();
        }
      },
      onPaymentCompleted: response => {
        setLocalMessages([messages.paymentComplete[response.resultCode]]);

        switch (response.resultCode) {
          case 'Authorised': {
            const staleHref = replaceQueryParameters(window.location.href, {
              [payment.staleKey]: payment.staleKey,
            });

            window.history.replaceState(null, document.title, staleHref);
            window.location.assign(payment.nextHref);

            break;
          }
          default: {
            if (checkout.current) {
              checkout.current.update();
            }
          }
        }
      },
      paymentMethodsConfiguration: {
        card: {
          /**
           * '01' (250*400) is the only size that fits 320 wide viewports, which we must support.
           *
           * https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-component/
           */
          challengeWindowSize: '01',
          hasHolderName: true,
          holderNameRequired: true,
          name: payment.cardName,
        },
      },
      session: payment.session,
    };

    const _checkout = await window.AdyenCheckout(adyenConfiguration);
    _checkout.create('dropin').mount(dropinContainer.current);
    checkout.current = _checkout;
  }, [isLoading, hasLoaded, payment]);

  return (
    <SalesLayout {...layout}>
      <div className="alternate-checkout-payment">
        <noscript>
          <Banner {...messages.noscript} />
        </noscript>
        <div className="alternate-checkout-payment__messages">
          {localMessages.map(entry => (
            <div key={entry.text}>
              <Banner {...entry} />
            </div>
          ))}
        </div>
        {summaries.map(({ information, price, priceLabel, rows, title }) => (
          <div
            className="alternate-checkout-payment__summary-wrapper"
            key={title}
          >
            <Text variant={Text.variants.hero} size={Text.sizes.basic}>
              {title}
            </Text>
            <div className="alternate-checkout-payment__summary">
              <div className="alternate-checkout-payment__summary-rows">
                {rows.map(({ label, value }) => (
                  <div
                    className="alternate-checkout-payment__summary-row"
                    key={label}
                  >
                    <Text>{label}</Text>
                    <Text theme={Text.themes.medium}>{value}</Text>
                  </div>
                ))}
              </div>
              <div className="alternate-checkout-payment__summary-price">
                <Text>{priceLabel}</Text>
                <Price {...price} />
              </div>
              {information ? (
                <Text
                  className="alternate-checkout-payment__summary-information"
                  size={Text.sizes.small}
                >
                  {information}
                </Text>
              ) : null}
            </div>
          </div>
        ))}
        {isLoading ? (
          <div aria-hidden className="alternate-checkout-payment__loader">
            <Spinner />
          </div>
        ) : null}
        <div ref={dropinContainer} />
      </div>
    </SalesLayout>
  );
};

export default AlternateCheckoutPayment;
