import React from 'react';

import Banner from '../../components/banner/banner';
import Choicebox from '../../components/choicebox/choicebox';
import DiscountSection from '../../components/discount-section/discount-section';
import LinkButton from '../../components/link-button/link-button';
import Price from '../../components/price/price';
import SalesLayout from '../../components/sales-layout/sales-layout';
import Text from '../../components/text/text';

import type { AlternateCheckoutMembership as Props } from './alternate-checkout-membership.types';

// NOTE: Dynamic client side updates
const AlternateCheckoutMembership: React.FC<Props> = ({
  actions,
  discount,
  layout,
  memberships,
  payments,
  priceSummary,
}) => (
  <SalesLayout {...layout}>
    <div className="alternate-checkout-membership">
      <div className="alternate-checkout-membership__group">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {memberships.title}
        </Text>
        <div className="alternate-checkout-membership__options">
          {memberships.options.map(entry => (
            <div key={entry.id}>
              <Choicebox {...entry} />
            </div>
          ))}
        </div>
      </div>
      <div className="alternate-checkout-membership__group">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {payments.title}
        </Text>
        <Banner {...payments.banner} theme={Banner.themes.information} />
        <div className="alternate-checkout-membership__options">
          {payments.options.map(entry => (
            <div key={entry.id}>
              <Choicebox {...entry} />
            </div>
          ))}
        </div>
      </div>
      <div className="alternate-checkout-membership__discount">
        <DiscountSection {...discount} />
      </div>
      {priceSummary.price ? (
        <div className="alternate-checkout-membership__price">
          <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
            {priceSummary.title}
          </Text>
          <Price {...priceSummary.price} />
        </div>
      ) : null}
      <div className="alternate-checkout-membership__actions">
        {actions.map(entry => (
          <div key={entry.href}>
            <LinkButton {...entry} wide />
          </div>
        ))}
      </div>
    </div>
  </SalesLayout>
);

export default AlternateCheckoutMembership;
