import React from 'react';

import Expander from 'root/client/components/expander/expander';
import SalesLayout from 'root/client/components/sales-layout/sales-layout';

import type { AlternateCheckoutSelectClub as Props } from './alternate-checkout-select-club.types';
import ClubLinkList from './club-link-list';

const AlternateCheckoutSelectClub: React.FC<Props> = ({ clubs, layout }) => (
  <SalesLayout {...layout}>
    {clubs && clubs.items.length ? (
      <div className="alternate-checkout-select-club">
        <Expander
          itemRenderer={ClubLinkList}
          {...clubs}
          theme={Expander.themes.standAlone}
        />
      </div>
    ) : null}
  </SalesLayout>
);

export default AlternateCheckoutSelectClub;
