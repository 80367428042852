import Close16 from '@sats-group/icons/16/close';
import cn from 'classnames';
import React from 'react';

import TabTrapper from 'root/client/components/tab-trapper/tab-trapper';
import Text from 'root/client/components/text/text';
import useEscape from 'root/client/hooks/use-escape';
import useIsMounted from 'root/client/hooks/use-is-mounted';

import type { Modal as Props } from './modal.types';
import { Theme } from './modal.types';

const Modal: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  themes: typeof Theme;
} = ({
  children,
  closeButtonText,
  hide = () => {},
  isVisible,
  theme,
  title,
}) => {
  const isMounted = useIsMounted();

  useEscape(hide);

  // NOTE: It's really important that the first render in the client matches the server HTML (`null`). Otherwise ReactDOM.hydrate messes up the HTML.
  if (!isMounted || !isVisible) {
    return null;
  }

  return (
    <TabTrapper isActive={true}>
      <div className={cn('modal', `modal--${theme}`)}>
        <div className="modal__inner">
          <div className="modal__nav">
            <div className="modal__title">
              <Text size={Text.sizes.header2} variant={Text.variants.hero}>
                {title}
              </Text>
            </div>
            <button
              className="modal__close"
              aria-label={closeButtonText}
              onClick={hide}
            >
              <Close16 />
            </button>
          </div>
          <div className="modal__children">{children}</div>
        </div>
        <div className="modal__background" onClick={hide} />
      </div>
    </TabTrapper>
  );
};

Modal.themes = Theme;

export default Modal;
