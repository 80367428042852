import React, { useEffect, useRef, useState } from 'react';

import apiHelper from 'root/client/ts/api-helper';
import useToggle from 'root/client/hooks/use-toggle';
import { fillRegistrationForm } from 'root/client/ts/registration-form-filler';

import Button from '../button/button';
import Checkbox from '../checkbox/checkbox';
import MembershipTerms from '../membership-terms/membership-terms';
import Message from '../message/message';
import Modal from '../modal/modal';
import RadioButton from '../radio-button/radio-button';
import Text from '../text/text';
import TextField from '../text-field/text-field';

import type { RegisterMemberInputs as Props } from './register-member-inputs.types';

const RegisterMemberInputs: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  buttonText,
  checkboxes,
  closeModalText,
  isDebugMode,
  missingValues,
  missingValuesTitle,
  postalAreaEndpoint,
  postalCodeErrorMessages,
  postalCodeInput,
  radios,
  radiosTitle,
  requiredFieldsExplanation,
  membershipTerms,
  textInputs,
}) => {
  const [isOpen, toggle, , close] = useToggle();
  const [postalArea, setPostalArea] = useState<string>();
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] =
    useState<string>();

  const postalCodeValue = useRef('');

  const getPostalArea = (value: string) => {
    if (value.length < 4) {
      return;
    }

    setPostalArea('');
    setPostalCodeErrorMessage('');
    postalCodeValue.current = value;

    apiHelper
      .get(`${postalAreaEndpoint}/${value}`)
      .then(data => {
        if (postalCodeValue.current === value) {
          setPostalArea(data.name);
        }
      })
      .catch(e => {
        if (e.responseStatus === 400 || e.responseStatus === 404) {
          setPostalCodeErrorMessage(postalCodeErrorMessages.notFound);
        } else {
          setPostalCodeErrorMessage(postalCodeErrorMessages.unknownError);
        }
      });
  };

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    getPostalArea(event.target.value);
  };

  useEffect(() => {
    if (postalCodeInput.defaultValue) {
      getPostalArea(postalCodeInput.defaultValue);
    }
  }, []);

  return (
    <div className="register-member-inputs">
      {missingValues ? (
        <Message type={Message.types.error}>
          {missingValuesTitle}
          <br />
          <ul>
            {missingValues.map(value => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </Message>
      ) : null}

      {isDebugMode ? (
        <div className="register-member-inputs__debug">
          <Button
            text="Populate registration field"
            onClick={() => fillRegistrationForm()}
          />
        </div>
      ) : undefined}

      <Text>* {requiredFieldsExplanation}</Text>

      {textInputs.map(({ id, inputs, name }) => {
        const isGroup = inputs.length > 1;

        return React.createElement(
          isGroup ? 'fieldset' : 'div',
          { key: id },
          <React.Fragment>
            {isGroup && name ? (
              <div className="register-member-inputs__group-name">
                <Text elementName="legend">{name}</Text>
              </div>
            ) : null}
            <div
              className={isGroup ? 'register-member-inputs__group' : undefined}
            >
              {inputs.map(input => (
                <div key={input.name}>
                  <TextField {...input} />
                </div>
              ))}
            </div>
          </React.Fragment>,
        );
      })}
      <div className="register-member-inputs__postal-area">
        <div>
          <TextField {...postalCodeInput} onChange={handlePostalCodeChange} />
        </div>
        {postalCodeErrorMessage ? (
          <div>
            <Message text={postalCodeErrorMessage} type="error" />
          </div>
        ) : null}
        {postalArea ? (
          <div>
            <Text>{postalArea}</Text>
          </div>
        ) : null}
      </div>

      <fieldset>
        <legend>
          {radiosTitle}
          {radios.some(({ isRequired }) => isRequired) ? <sup>*</sup> : null}
        </legend>
        {radios.map(radio => (
          <div key={radio.value}>
            <RadioButton {...radio} />
          </div>
        ))}
      </fieldset>
      <div>
        {checkboxes.map(checkbox => (
          <div key={checkbox.name}>
            <Checkbox {...checkbox} />
          </div>
        ))}
      </div>
      <div>
        <Button
          aria-expanded={isOpen}
          data-test-terms-trigger
          onClick={toggle}
          size={Button.sizes.small}
          theme={Button.themes.secondary}
          text={buttonText}
        />
      </div>
      {membershipTerms ? (
        <Modal
          hide={close}
          isVisible={isOpen}
          theme={Modal.themes.Centered}
          closeButtonText={closeModalText}
        >
          <MembershipTerms {...membershipTerms} />
        </Modal>
      ) : null}
    </div>
  );
};

export default RegisterMemberInputs;
