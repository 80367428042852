import type { ReactNode } from 'react';
import type { ValueOf } from 'type-fest';

import type { Link } from '../link/link.types';
import type { LinkButton } from '../link-button/link-button.types';

export const themes = {
  standAlone: 'stand-alone',
};

type ExpanderListItem = {
  actions: LinkButton[];
  defaultOpen?: boolean;
  faq?: boolean;
  icon?: ReactNode;
  id: string;
  links?: Pick<Link, 'href' | 'text'>[];
  metadata?: string;
  title: string;
  value?: number;
};

export type Expander<T> = {
  description?: string;
  faq?: boolean;
  items: {
    listItemProps: ExpanderListItem;
    props: T;
  }[];
  theme?: ValueOf<typeof themes>;
  title?: string;
};
