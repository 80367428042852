import { useMask } from '@react-input/mask';
import React, { useEffect, useRef, useState } from 'react';

import { fillRegistrationForm } from 'root/client/ts/registration-form-filler';
import apiHelper from 'root/client/ts/api-helper';
import Button from 'root/client/components/button/button';
import Checkbox from 'root/client/components/checkbox/checkbox';
import HiddenInput from 'root/client/components/hidden-input/hidden-input';
import Link from 'root/client/components/link/link';
import LinkButton from 'root/client/components/link-button/link-button';
import MembershipTerms from 'root/client/components/membership-terms/membership-terms';
import Message from 'root/client/components/message/message';
import Modal from 'root/client/components/modal/modal';
import PhoneInput from 'root/client/components/phone-input/phone-input';
import RadioButton from 'root/client/components/radio-button/radio-button';
import SalesLayout from 'root/client/components/sales-layout/sales-layout';
import Text from 'root/client/components/text/text';
import TextField from 'root/client/components/text-field/text-field';
import useToggle from 'root/client/hooks/use-toggle';

import type { AlternateCheckoutAboutYou as Props } from './alternate-checkout-about-you.types';

const AlternateCheckoutAboutYou: React.FC<Props> = ({
  debug,
  form,
  layout,
  previous,
}) => {
  const [isOpen, toggle, , close] = useToggle();
  const [postalArea, setPostalArea] = useState<string>();
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] =
    useState<string>();

  const postalCodeValue = useRef('');
  const birthdayRef = useMask({
    mask: 'dd-mm-åååå',
    replacement: { d: /\d/, m: /\d/, å: /\d/ },
    showMask: true,
  });

  const getPostalArea = (value: string) => {
    if (value.length < 4) {
      return;
    }

    setPostalArea('');
    setPostalCodeErrorMessage('');
    postalCodeValue.current = value;

    apiHelper
      .get(`${form.inputs.postalCode.endpoint}/${value}`)
      .then(data => {
        if (postalCodeValue.current === value) {
          setPostalArea(data.name);
        }
      })
      .catch(e => {
        if (e.responseStatus === 400 || e.responseStatus === 404) {
          setPostalCodeErrorMessage(
            form.inputs.postalCode.errorMessages.notFound,
          );
        } else {
          setPostalCodeErrorMessage(
            form.inputs.postalCode.errorMessages.unknownError,
          );
        }
      });
  };

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    getPostalArea(event.target.value);
  };

  useEffect(() => {
    if (form.inputs.postalCode.input.defaultValue) {
      getPostalArea(form.inputs.postalCode.input.defaultValue);
    }
  }, []);

  return (
    <SalesLayout {...layout}>
      {debug ? (
        <div className="alternate-checkout-about-you__debug">
          <Button text="Fill form" onClick={fillRegistrationForm} />
        </div>
      ) : null}
      <div className="alternate-checkout-about-you">
        <form
          className="alternate-checkout-about-you__form"
          action={form.endpoint}
          method="POST"
        >
          <Text variant={Text.variants.hero} size={Text.sizes.large}>
            {form.title}
          </Text>
          <div className="alternate-checkout-about-you__form-inputs">
            {form.inputs.hiddenInputs.map(input => (
              <HiddenInput {...input} />
            ))}
            {postalArea ? (
              <HiddenInput {...form.inputs.postalArea} value={postalArea} />
            ) : null}
            <TextField {...form.inputs.firstName} wide />
            <TextField {...form.inputs.lastName} wide />
            <TextField {...form.inputs.email} wide />
            <PhoneInput {...form.inputs.phone} required />
            <TextField ref={birthdayRef} {...form.inputs.birthday} />
            <TextField {...form.inputs.address} wide />
            <TextField
              {...form.inputs.postalCode.input}
              onChange={handlePostalCodeChange}
            />

            {postalCodeErrorMessage ? (
              <div>
                <Message text={postalCodeErrorMessage} type="error" />
              </div>
            ) : null}
            {postalArea ? (
              <div>
                <Text>{postalArea}</Text>
              </div>
            ) : null}
          </div>
          <div className="alternate-checkout-about-you__form-options">
            {form.inputs.genders.title}
            {form.inputs.genders.options.map(radio => (
              <RadioButton key={radio.value} {...radio} />
            ))}
          </div>
          {form.inputs.optIns ? (
            <div className="alternate-checkout-about-you__form-options">
              {form.inputs.optIns.options.map(checkbox => (
                <Checkbox key={checkbox.name} {...checkbox} />
              ))}
              <div>
                <Link
                  href="#"
                  aria-expanded={isOpen}
                  data-test-terms-trigger
                  onClick={toggle}
                  text={form.inputs.optIns.membershipTerms.open}
                />
              </div>
              <Modal
                hide={close}
                isVisible={isOpen}
                theme={Modal.themes.Centered}
                {...form.inputs.optIns.membershipTerms.modal}
              >
                <MembershipTerms
                  {...form.inputs.optIns.membershipTerms.terms}
                />
              </Modal>
            </div>
          ) : null}
          <div className="alternate-checkout-about-you__actions">
            <div>
              <LinkButton {...previous} theme={LinkButton.themes.secondary} />
            </div>
            <div>
              <Button type="submit" text={form.submitLabel} />
            </div>
          </div>
        </form>
      </div>
    </SalesLayout>
  );
};

export default AlternateCheckoutAboutYou;
